body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

#login {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
}

.MuiStepConnector-vertical {
  padding: 4px 0 !important;
}

/* Image Gallery Fixes */

.image-gallery-svg {
  max-width: 40px !important;
  max-height: 80px !important;
  min-width: 24px !important;
  min-height: 24px !important;
}
.image-gallery-image, .image-gallery-thumbnail-image, .ril-image-current {
  background: #000000;
}

@keyframes pulse {
  0% {
    filter: brightness(80%);
  }
  45% {
    filter: brightness(100%);
  }
  80% {
    box-shadow: 0 0 0 8px #00000000;
  }
  100% {
    filter: brightness(80%);
    box-shadow: 0 0 0 0 #00000000;
  }
}